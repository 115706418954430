<template>
    <div>
        <div class="cop_title">
            <img src="../../assets/img/detail/title_ic17.png" />
            COMPARE COMPANIES
        </div>

        <div class="cop_cont_box">
            <div class="cop_cont_head flex_box">
                <div class="cop_company">Company</div>
                <div class="cop_add">
                    <div class="flex_center" v-show="isDelOne" @click="(addIndex = 2), Add()">
                        <i class="cop_add_ic"></i>
                        Add a Company
                    </div>
                    <div class="flex_center" v-show="!isDelOne" @click="(addIndex = 2), delCompany()">
                        <i class="cop_del_ic"></i>
                        Delete a Company
                    </div>
                </div>
                <div class="cop_add">
                    <div class="flex_center" v-show="isDelTwo" @click="(addIndex = 3), Add()">
                        <i class="cop_add_ic"></i>
                        Add a Company
                    </div>
                    <div class="flex_center" v-show="!isDelTwo" @click="(addIndex = 3), delCompany()">
                        <i class="cop_del_ic"></i>
                        Delete a Company
                    </div>
                </div>
            </div>
            <div class="cop_cont_cont flex_box">
                <div class="list title">
                    <div class="item">Company Name:</div>
                    <div class="item">Company Native Name:</div>
                    <div class="item">Legal Status:</div>
                    <div class="item">Legal Form:</div>
                    <div class="item">Years in Business:</div>
                    <div class="item">Reg.Capital:</div>
                    <div class="item">ISIC:</div>
                    <div class="item">Industry Description:</div>
                    <div class="item">Listed:</div>
                    <div class="item">No.of Employees:</div>
                    <div class="item">No.of shareholders:</div>
                    <div class="item">Revenue:</div>
                    <div class="item">Net income:</div>
                    <div class="item">Total Assets:</div>
                    <div class="item">Profit margin:</div>
                    <div class="item">Profitability:</div>
                    <div class="item">Black List:</div>
                    <div class="item">Credit Score:</div>
                    <div class="item" v-show="lastView || lastView3"></div>
                </div>
                <div class="list content">
                    <div class="item">
                        <div class="line_2">{{ compObj.CompanyName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.CompanyNativeName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">
                            <div class="line_2">{{ compObj.status }}</div>
                            <!-- <div class="c_d_tag">
                                <el-tag v-show="!showTranslate" v-for="(item, index) in compObj.status" :key="index + item">{{ item }}</el-tag>
                                <el-tag v-show="showTranslate" v-for="(item, index) in compObj.status" :key="index">{{ item}}</el-tag>
                            </div> -->
                        </div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.LegalForm }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.YearsinBusiness }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.RegCapital }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.isic }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.IndustryDescription }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Listed }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.NoofEmployees }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Noofshareholders }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Revenue }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Netincome }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.TotalAssets }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Profitmargin }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.Profitability }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.BlackList }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj.CreditScore }}</div>
                    </div>
                    <div class="item" v-show="lastView || lastView3"></div>
                </div>
                <div class="list content">
                    <div class="item">
                        <div class="line_2">{{ compObj2.CompanyName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.CompanyNativeName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">
                            {{ compObj2.status }}
                            <!-- <div class="c_d_tag">
                                <el-tag v-show="!showTranslate" v-for="(item, index) in compObj2.status" :key="index + item">{{ item }}</el-tag>
                                <el-tag v-show="showTranslate" v-for="(item, index) in compObj2.status" :key="index">{{ item }}</el-tag>
                            </div> -->
                        </div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.LegalForm }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.YearsinBusiness }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.RegCapital }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.isic }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.IndustryDescription }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Listed }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.NoofEmployees }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Noofshareholders }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Revenue }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Netincome }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.TotalAssets }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Profitmargin }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.Profitability }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.BlackList }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj2.CreditScore }}</div>
                    </div>
                    <div class="item" v-show="lastView">
                        <button class="btn_view" @click="ViewDetails(2)">View Details</button>
                        <el-tooltip v-if="updateDisabled" content="It normally takes 2-4 days to deliver,this updated rerpot." :visible-arrow="false" placement="top" effect="light">
                            <button class="btn_gray">In Production...</button>
                        </el-tooltip>
                        <button class="btn_view" v-else @click="getPDFFile(2)">Order Update Report</button>
                    </div>
                </div>
                <div class="list content">
                    <div class="item">
                        <div class="line_2">{{ compObj3.CompanyName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.CompanyNativeName }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">
                            {{ compObj3.status }}
                            <!-- <div class="c_d_tag">
                                <el-tag v-show="!showTranslate" v-for="(item, index) in compObj3.status" :key="index + item">{{ item }}</el-tag>
                                <el-tag v-show="showTranslate" v-for="(item, index) in compObj3.status" :key="index">{{ item}}</el-tag>
                            </div> -->
                        </div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.LegalForm }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.YearsinBusiness }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.RegCapital }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.isic }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.IndustryDescription }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Listed }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.NoofEmployees }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Noofshareholders }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Revenue }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Netincome }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.TotalAssets }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Profitmargin }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.Profitability }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.BlackList }}</div>
                    </div>
                    <div class="item">
                        <div class="line_2">{{ compObj3.CreditScore }}</div>
                    </div>
                    <div class="item" v-show="lastView3">
                        <button class="btn_view" @click="ViewDetails(3)">View Details</button>
                        <el-tooltip v-if="updateDisabled3" content="It normally takes 2-4 days to deliver,this updated rerpot." :visible-arrow="false" placement="top" effect="light">
                            <button class="btn_gray">In Production...</button>
                        </el-tooltip>
                        <button class="btn_view" v-else @click="getPDFFile(3)">Order Update Report</button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="addDialog" center :close-on-click-modal="false" custom-class="dialog_normal2" :append-to-body="true">
            <div class="detail_cont">
                <div class="dialog_detail_title">Add a Company</div>
                <div class="head_search flex_center" :class="{ search2: seachdoudong }" ref="input">
                    <!-- 选择国家 -->
                    <div class="search_left" :class="countryImg ? 'on' : ''">
                        <div class="country_flag">
                            <img :class="countryImg ? 'country_flag_img' : 'none'" :src="countryImg" />
                        </div>
                        <el-select v-model="countryCode" ref="select" clearable @clear="clearCountry" placeholder="Select Country" popper-class="sel_box" @change="selectCountry" no-match-text="No data" no-data-text="No data">
                            <div class="search_sel_container">
                                <el-tabs tab-position="left" v-model="tabActiveName">
                                    <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                                        <div slot="label" class="flex_center_between_box">
                                            <span class="left_txt">{{ tab.continent.enName }}</span>
                                            <span class="right_txt">{{ tab.continent.countryNumStr }}</span>
                                        </div>
                                        <div class="right">
                                            <el-option v-for="item in tab.countryList" :key="item.id" :value="item.countryCode" :label="item.nameEn" @click.native="labelClick(item.countryCode)">
                                                <div class="right_item flex_box">
                                                    <div class="img flex_center">
                                                        <img :src="item.urlBanner" />
                                                    </div>

                                                    <div class="rBox">
                                                        <div class="coun_title">{{ item.nameEn }}</div>
                                                        <div class="coun_num">{{ item.companyNumStr }}</div>
                                                    </div>
                                                </div>
                                            </el-option>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </el-select>
                    </div>

                    <!-- 搜索 -->
                    <div class="search_right flex_center">
                        <input type="text" placeholder="Enter company name" v-model="searchName" class="search_input" @keyup.enter="searchCompanyByName()" />
                    </div>
                    <div class="search_btn flex_center">
                        <img src="../../assets/img/head_search_ic.png" @click="searchCompanyByName()" />
                    </div>
                </div>

                <div class="right_cont_box" style="position: relative; margin-top: 40px">
                    <div class="table_box draggable">
                        <el-table ref="treeTable" :key="refresh" :data="parentTable" border row-key="aaa_id" @selection-change="handleSelectionChange" @select-all="handleSelectAll" style="width: 100%" v-loading="parentTableloding">
                            <el-table-column type="selection" width="40"></el-table-column>

                            <el-table-column label="Company Name" width="385" prop="" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi">
                                <template slot-scope="scope">
                                    <div class="line_1" style="width: 365px">
                                        <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                        <div @click="setid(scope.row)" class="tbl_company line_1" v-show="showTranslate">{{ scope.row.company_name }}</div>
                                        <div @click="setid(scope.row)" class="tbl_company line_1" v-show="!showTranslate">{{ scope.row.company_name_en }}</div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="Location" width="200" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div v-show="showTranslate" style="width: 180px" class="line_1">
                                        {{ scope.row.city_cn }}
                                        <span class="tbl_dot" v-show="scope.row.province && scope.row.province != ' ' && scope.row.province != '' && scope.row.city_cn != '' && scope.row.city_cn != ' ' && scope.row.city_cn">,</span>
                                        {{ scope.row.province }}
                                    </div>
                                    <div v-show="!showTranslate" style="width: 180px" class="line_1">
                                        {{ scope.row.city_en }}
                                        <span class="tbl_dot" v-show="scope.row.province_en && scope.row.province_en != ' ' && scope.row.province_en != '' && scope.row.city_en != '' && scope.row.city_en != ' ' && scope.row.city_en">,</span>
                                        {{ scope.row.province_en }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Industry" prop="isic_en" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="Type" width="120" prop="company_class_en" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div style="width: 100px" class="line_1">
                                        {{ scope.row.company_class_en }}
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="Size" width="80" :prop="showTranslate == false ? 'scale_en' : 'scale_en'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Status" width="80" :prop="showTranslate == false ? 'status_en' : 'status_en'" align="right" show-overflow-tooltip :resizable="false" />
                            <template v-if="ret" slot="empty"><div style="padding: 20px 40px; text-align: left; line-height: 40px; ">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div></template>

                            <template v-else slot="empty">
                                <div style="text-align: left">No Data</div>
                            </template>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="create_item">
                    <div class="create_item_lab">
                        <span>Country:</span>
                    </div>
                    <div class="sel_coun">
                        <div class="country_img" v-show="countryImg"><img :src="countryImg" /></div>
                        <el-select v-model="countryVal" placeholder="" no-data-text="No Data" @change="selectCountry" class="country_sel">
                            <el-option v-for="item in selCountryList" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="create_item">
                    <div class="create_item_lab">
                        <span>Enter English Name/Native Name</span>
                    </div>
                    <div class="name_ser">
                        <el-autocomplete class="inline-input" ref="autocomplete" v-model="enName" :fetch-suggestions="querySearch" :trigger-on-focus="false" placeholder="" @select="handleSelect">
                            <template slot-scope="{ item }" v-if="item">
                                <el-radio :label="item.company_name_en">{{ item.company_name_en }}</el-radio>
                            </template>
                            <i slot="suffix" class="ser_icon" @click="serchName"></i>
                        </el-autocomplete>
                    </div>
                </div> -->

                <!-- <div class="btn_box flex_center_between_box">
                    <button class="btn_export" :disabled="addDisabled" @click="submitAdd()">Add</button>
                    <button class="btn_return" @click="cancelAdd">Cancel</button>
                </div> -->
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { searchCompany } from '@/api/searchApi.js';
import { getCompanyCompare, getCountryCollection, getCompanySnap, ordersAdd } from '@/api/companyApi.js';
import { getPdfAddress, getPdfPurchase, getPdfhk_pdf } from '@/api/userApi.js';
import bus from '../../components/bus';
import crypto from '../../utils/crypto';
import { mixins1 } from '@/mixins/index';
import Page from '@/components/page';
// import noDate from '@/components/tablenoda';

export default {
    mixins: [mixins1],
    components: { Page },

    data() {
        return {
            compObj: {
                CompanyName: 'COSTCO WHOLESALE CORPORATION',
                CompanyNativeName: '',
                LegalStatus: [],
                LegalForm: '',
                YearsinBusiness: '',
                RegCapital: '',
                isic: '',
                IndustryDescription: '',
                Listed: '',
                NoofEmployees: '',
                Noofshareholders: '',
                Revenue: '',
                Netincome: '',
                TotalAssets: '',
                Profitmargin: '',
                Profitability: '',
                BlackList: '',
                CreditScore: '',
            },
            compObj2: {},
            compObj3: {},
            countryVal: '',
            selCountryList: [],
            enName: '',
            addIndex: 1,
            addDialog: false,
            restaurants: [],
            lastView: false,
            lastView3: false,
            isDelOne: true,
            isDelTwo: true,
            addDisabled: true,
            companyValList: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            id3a: '',
            companyCountry: '',
            companyNameEn: '',
            companyNameCn: '',
            id3a3: '',
            companyCountry3: '',
            companyNameEn3: '',
            companyNameCn3: '',
            countryObj: {},
            updateDisabled: false,
            updateDisabled3: false,
            orderStatus: '',
            offlineOrderId: '',
            offlineOrderId3: '',

            seachdoudong: false,
            //国家分类
            tabCountryList: [],
            productloding: false,
            percentageerr: false,
            countryCode1: '',
            countryCode: '',
            countryName: '', //选中国家
            countryImg: '', //选中国家的国旗
            tabActiveName: '', //选择的区域
            isNotice: true, //通知高亮
            searchName: '',
            parentTable: [],
            childTable: [],
            expands: [],
            branchflag: '',
            refresh: 0,
            parentTableloding: true,
            ret: false,
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
    },
    created() {
        this.getData();
    },
    methods: {
        handleSelectAll(selection) {
            if (selection.length > 1) {
                this.$refs.treeTable.clearSelection();
                this.$refs.treeTable.toggleRowSelection(selection[0]);
            }
        },
        // 全选
        handleSelectionChange(selection) {
            if (selection.length > 0) {
                this.handleSelect(selection[0]);
            }
        },
        // 国旗跳转
        gotocountry(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.company_country,
            });

            window.open(routeData.href, '_blank');
        },
        // 点击跳转
        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));

            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaa_id + '&companyCountry=' + row.company_country + '&companyCountrydqs=' + this.$route.query.companyCountrydqs,
            });

            window.open(routeData.href, '_blank');
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.searchCompanyByName();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.searchCompanyByName();
        },

        //清除选择的国家
        clearCountry() {
            this.countryImg = '';
            this.countryCode = '';
            this.countryCode1 = '';
        },

        //下拉选择国家
        selectCountry(val) {
            let countryImg = this.getCountryImgUrl(val);
            let flag = require('../../../static' + countryImg);
            this.countryImg = flag;
            this.countryCode = val;

            let newscountry = this.tabCountryList[0].countryList;
            newscountry.map(item => {
                if (item.countryCode == val) {
                    this.countryCode1 = item.dowjonesCountrycode;
                }
            });
        },
        // 选中国家
        labelClick(label) {},
        async getData() {
            const companyBase = JSON.parse(localStorage.getItem('companyBase1'));
            bus.$emit('toRightHeadObj', companyBase);
            // const status = base && base.lables ? base.lables : [];
            const id3a = this.$route.query.id3a ? this.$route.query.id3a : '';
            const companyCountry = this.$route.query.companyCountry ? this.$route.query.companyCountry : '';
            let result = await getCompanyCompare('id3a=' + id3a + '&companyCountry=' + companyCountry);
            //解密
            let data = JSON.parse(crypto.decrypt(result));
            if (data && data.code) {
                let res = data.data;
                let status = res.status;
                this.compObj = {
                    CompanyName: res.nameEn,
                    CompanyNativeName: res.name,
                    LegalStatus: res.lables,
                    // Company Legal Status
                    LegalForm: res.legalForm,
                    YearsinBusiness: res.yearsInBusiness,
                    RegCapital: res.regCapital,
                    isic: res.isic,
                    IndustryDescription: res.industryDescription,
                    Listed: res.listed,
                    NoofEmployees: res.noOfEmployees,
                    Noofshareholders: res.noOfShareholders,
                    Revenue: res.revenue,
                    Netincome: res.netIncome,
                    TotalAssets: res.totalAssets,
                    status: res.status,
                    Profitmargin: res.profitMargin,
                    Profitability: res.profitability,
                    BlackList: res.blackList,
                    CreditScore: res.creditScore,
                };
            }
        },
        //搜索公司
        async searchCompanyByName() {
            this.parentTableloding = true;
            let params = {
                company_name: this.searchName,
                company_country: this.countryCode,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
            };
            let res = await searchCompany(params);
            this.parentTable = [];

            if (res && res.code) {
                this.page.total = res.data.total;
                let list = (this.parentTable = res.data.rows);
                //查找本地图片
                list = list.map((item, index) => {
                    let countryImg = this.getCountryImgUrl(item.company_country);
                    let flag = require('../../../static' + countryImg);
                    this.parentTable[index].flag = flag;
                    this.refresh++;
                });
                this.parentTableloding = false;
            } else {
                this.loading = false;
                // this.addPopIndex2=true
                this.parentTable = [];
                this.parentTableloding = false;
                if (res.ret == '1002') {
                    console.log(this.ret);
                    this.ret = true;
                } else {
                    this.ret = false;
                }
            }
            // }
        },
        //获取国家
        async getAllCountry() {
            // 优化接口
            let countryCode = '';
            if (JSON.parse(localStorage.getItem('countryList'))) {
                this.selCountryList = JSON.parse(localStorage.getItem('countryList'))[0].countryList;
                this.tabCountryList = JSON.parse(localStorage.getItem('countryList'));
                let list = this.tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        let countryImg = '';
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        let flag = require('../../../static' + countryImg);
                        it.urlBanner = flag;
                        if (countryCode && it.countryCode == countryCode) {
                            this.countryName = it.nameEn;
                            this.countryImg = it.urlBanner;
                        }
                    });
                });
            } else {
                let res = await getCountryCollection();
                if (res && res.code) {
                    this.selCountryList = res.data.rows[0].countryList;
                    this.tabCountryList = JSON.parse(localStorage.getItem('countryList')) == null ? res.data.rows : JSON.parse(localStorage.getItem('countryList'));
                    let list = this.tabCountryList;
                    list.forEach((item, index) => {
                        item.countryList.forEach(it => {
                            let countryImg = '';
                            if (it.urlBanner == null) {
                                countryImg = '/imagev2/zanwutupian.png';
                            } else {
                                countryImg = it.urlBanner.split('/static')[1];
                            }
                            let flag = require('../../../static' + countryImg);
                            it.urlBanner = flag;
                            if (countryCode && it.countryCode == countryCode) {
                                this.countryName = it.nameEn;
                                this.countryImg = it.urlBanner;
                            }
                        });
                    });
                }
            }
        },

        //add compant弹框
        Add() {
            this.getAllCountry();
            this.countryImg = '';
            this.countryVal = '';
            this.searchName = '';
            this.addDialog = true;
            // this.parentTable=[]
            this.searchCompanyByName();
        },
        // 确定提交
        async submitAdd() {
            let id3a = this.addIndex == 2 ? this.id3a : this.id3a3;
            let companyCountry = this.addIndex == 2 ? this.companyCountry : this.companyCountry3;
            let params = 'aaaId=' + id3a;
            let that = this;
            getPdfPurchase(params).then(res => {
                // if (res.code) {
                let repordshow = res.data?.onlineReport?.type;
                if (repordshow != 1) {
                    let type = that.addIndex;
                    this.getOrdersAdd(type, 1).then(res => {
                        if (res) {
                            this.geitadddate(id3a, companyCountry);
                        }
                    });
                } else {
                    this.geitadddate(id3a, companyCountry);
                }
                // }
            });
        },
        async geitadddate(id3a, companyCountry) {
            let CompanyName = this.addIndex == 2 ? this.companyNameEn : this.companyNameEn3;
            let CompanyNativeName = this.addIndex == 2 ? this.companyNameCn : this.companyNameCn3;
            let result = await getCompanyCompare('id3a=' + id3a + '&companyCountry=' + companyCountry);
            //解密
            let data = JSON.parse(crypto.decrypt(result));
            if (data && data.code) {
                let res = data.data;
                let obj = {
                    CompanyName: CompanyName,
                    CompanyNativeName: CompanyNativeName,
                    LegalStatus: res.lables,
                    LegalForm: res.legalForm,
                    YearsinBusiness: res.yearsInBusiness,
                    RegCapital: res.regCapital,
                    isic: res.isic,
                    IndustryDescription: res.industryDescription,
                    Listed: res.listed,
                    NoofEmployees: res.noOfEmployees,
                    Noofshareholders: res.noOfShareholders,
                    Revenue: res.revenue,
                    Netincome: res.netIncome,
                    TotalAssets: res.totalAssets,
                    Profitmargin: res.profitMargin,
                    Profitability: res.profitability,
                    BlackList: res.blackList,
                    status: res.status,
                    CreditScore: res.creditScore,
                };
                if (this.addIndex == 2) {
                    this.compObj2 = obj;
                    this.isDelOne = false;
                    this.lastView = true;
                    //查看PDF状态
                    this.hasPdfPurchase(2);
                } else {
                    this.compObj3 = obj;
                    this.isDelTwo = false;
                    this.lastView3 = true;

                    //查看PDF状态
                    this.hasPdfPurchase(3);
                }
                this.addDialog = false;
            }
        },

        handleSelect(item) {
            this.searchName = item.company_name_en;
            this.addDisabled = false;

            this.countryObj = item;
            if (this.addIndex == 2) {
                this.id3a = item.aaa_id;
                this.companyCountry = item.company_country;
                this.companyNameCn = item.company_name;
                this.companyNameEn = item.company_name_en;
            } else {
                this.id3a3 = item.aaa_id;
                this.companyCountry3 = item.company_country;
                this.companyNameCn3 = item.company_name;
                this.companyNameEn3 = item.company_name_en;
            }
            this.submitAdd();
        },
        //删除添加
        delCompany() {
            if (this.addIndex == 2) {
                this.compObj2 = {};
                this.isDelOne = true;
                this.lastView = false;
                this.parentTable = [];
            } else {
                this.compObj3 = {};
                this.isDelTwo = true;
                this.lastView3 = false;
                this.parentTable = [];
            }
        },

        //  跳转公司详情
        ViewDetails(val) {
            let id3a = val == 2 ? this.id3a : this.id3a3;
            let companyCountry = val == 2 ? this.companyCountry : this.companyCountry3;
            this.$emit('updateSubLink', 0);
            sessionStorage.setItem('company', JSON.stringify(this.countryObj));
            // this.$router.push({
            //     path: '/company/companysnapshot?id3a=' + id3a + '&companyCountry=' + companyCountry,
            // });

            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + id3a + '&companyCountry=' + companyCountry,
            });

            window.open(routeData.href, '_blank');
        },

        //step-1  获取 判断是否要扣点 线上报告,线下报告
        getPDFFile(val) {
            //有订单号吗
            if (!this.offlineOrderId) {
                let type = 2;
                this.getOrdersAdd(val, type).then(res => {
                    if (res) this.hanldPdfPurchase();
                });
            } else {
                this.hanldPdfPurchase();
            }
        },
        //step-3   1.查找订单；2.下载PDF
        async hanldPdfPurchase() {
            if (this.offlineOrderId) {
                await getPdfAddress(this.offlineOrderId).then(res => {
                    if (res && res.code) {
                        window.open(res.data, '_blank');
                    } else {
                        this.$message({
                            message: 'It normally takes 2-4 days to deliver,this updated rerpot.',
                            duration: 5000,
                        });
                    }
                });
            }
        },
        //step-2 是否购买PDF,获取订单号
        async hasPdfPurchase(val) {
            let id3a = val == 2 ? this.id3a : this.id3a3;
            let params = 'aaaId=' + id3a;
            await getPdfPurchase(params).then(async res => {
                if (res && res.code) {
                    if (res.data.offlineReport) {
                        this.offlineOrderId = res.data.offlineReport.id;
                        this.orderStatus = res.data.offlineReport.orderStatus;
                        if (this.orderStatus == '4') {
                            if (val == 2) {
                                this.updateDisabled = true;
                            } else {
                                this.updateDisabled3 = true;
                            }
                        } else {
                            if (val == 2) {
                                this.updateDisabled = false;
                            } else {
                                this.updateDisabled3 = false;
                            }
                        }
                    }
                } else {
                    if (val == 2) {
                        this.updateDisabled = false;
                    } else {
                        this.updateDisabled3 = false;
                    }
                }
            });
        },
        /**
         *用户购买报告 "type": 1 线下，2 线上, 13 Import/Export
         */
        async getOrdersAdd(val, type) {
            let id3a = val == 2 ? this.id3a : this.id3a3;
            let companyCountry = val == 2 ? this.companyCountry : this.companyCountry3;
            let companyName = val == 2 ? this.companyNameEn : this.companyNameEn3;
            //1  :线上报告
            let params = {
                companyName: companyName, //必须
                aaaId: id3a, //必须
                companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    this.hasPdfPurchase(val);
                    status = true;
                    this.$message({
                        message: data.msg,
                        type: 'success',
                        // duration: 5000,
                    });
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            return status;
        },

        // // 清除😕选择公司
        // cancelAdd() {
        //     this.addDialog = false;
        //     this.countryImg = '';
        //     this.countryVal = '';
        //     this.enName = '';
        //     this.restaurants = [];
        // },
        // async serchName() {
        //     var list = [];
        //     this.$refs.autocomplete.suggestions = [];
        //     let params = {
        //         company_name: this.enName,
        //         company_country: this.countryVal,
        //         page: this.page.pageNo,
        //         pageSize: this.page.pageSize,
        //     };
        //     let res = await searchCompany(params);
        //     if (res && res.code) {
        //         this.page.total = res.data.total;
        //         this.restaurants = res.data.rows;
        //         for (let i of this.restaurants) {
        //             i.value = i.company_name_en; //将想要展示的数据作为value
        //         }
        //         list = this.restaurants;
        //         this.$refs.autocomplete.suggestions = list;
        //     }
        // },
        //queryString 为在框中输入的值
        //         async querySearch(queryString, callback) {
        //     var list = [];
        //     this.$refs.autocomplete.suggestions = [];
        //     let params = {
        //         company_name: this.enName,
        //         company_country: this.countryVal,
        //         page: this.page.pageNo,
        //         pageSize: this.page.pageSize,
        //     };
        //     let res = await searchCompany(params);
        //     if (res && res.code) {
        //         this.page.total = res.data.total;
        //         this.restaurants = res.data.rows;
        //         for (let i of this.restaurants) {
        //             i.value = i.company_name_en; //将想要展示的数据作为value
        //         }
        //         list = this.restaurants;
        //         callback(list);
        //     } else {
        //         this.restaurants = [];
        //         list = [];
        //         callback(list);
        //     }
        // },
    },
};
</script>
<style scoped lang="less">
.sel_coun {
    position: relative;
}
.country_img {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 8;
}
.country_img img {
    width: 46px;
    height: 32px;
}

.el-select,
.el-autocomplete {
    width: 100%;
}
.create_item /deep/ .el-input__inner {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #8497ab;
}
.country_sel /deep/ .el-input__inner {
    padding-left: 55px;
}
.create_item /deep/ .el-autocomplete .el-input__inner {
    background-image: url(../../assets/img/head_search_ic.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 98%;
    padding-right: 60px;
}
.create_item {
    margin-bottom: 20px;
}
.cop_title img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.cop_title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Arial Bold';
    margin-top: 60px;
    margin-bottom: 20px;
}
.cop_cont_head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-radius: 14px 14px 0px 0px;
    border: 1px solid #e7eaed;
    background: #f2f5f9;
    color: #8497ab;
    font-size: 14px;
}
.cop_company {
    width: 578px;
    line-height: 38px;
    padding-left: 400px;
}
.cop_add {
    width: 264px;
    height: 40px;
    cursor: pointer;
}
.cop_add .flex_center {
    height: 40px;
}
.cop_add_ic {
    background: #1290c9;
    background-image: url(../../assets/img/detail/add_ic.png);
}
.cop_del_ic {
    background: #f56b6b;
    background-image: url(../../assets/img/detail/del_ic.png);
}
.cop_del_ic,
.cop_add_ic {
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
    margin-right: 8px;
}

.cop_cont_box {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
    padding-top: 40px;
}
.cop_cont_cont .item {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 16px;
    height: 64px;
    line-height: 24px;
    border-bottom: 1px solid #e7eaed;
}
.cop_cont_cont .list.title .item {
    width: 280px;
    overflow: hidden;
    padding: 8px 20px;
    color: #8497ab;
}
.cop_cont_cont .list.content {
    width: 300px;
    border-left: 1px solid #e7eaed;
}
.cop_cont_cont .list.content.last {
    width: 266px;
}
.line_2 .c_d_tag {
    width: 245px;
}
.c_d_tag .el-tag {
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ff7600;
    font-size: 12px;
    color: #ff7600;
    margin: 0 4px 2px;
}
.btn_box {
    margin-top: 30px;
}
.btn_return {
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}
.create_item_lab {
    font-size: 16px;
    color: #022955;
    margin-bottom: 8px;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}
.btn_view {
    padding: 8px 6px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    height: 34px;
    line-height: 18px;
    font-size: 14px;
}
.btn_view + .btn_view {
    margin-left: 8px;
}
.name_ser {
    position: relative;
}
.ser_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 40px;
}
.btn_gray,
.btn_blue {
    width: 140px;
    height: 34px;
    line-height: 34px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.btn_gray,
.btn_blue:disabled {
    color: #8497ab;
    background: #e7eaed;
}
.btn_gray {
    margin-left: 10px;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
// .right_box/deep/ .el-dialog__wrapper {
//     overflow: visible;
//     z-index: 2004 !important;
//     position: fixed;
//     top: 131px;
//     right: 0;
//     bottom: 0;
//     left: 0;
// }

.el-radio__inner {
    width: 20px;
    height: 20px;
}

/* 搜索 */
.head_search {
    width: 950px;
    height: 54px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #dddddd;
    color: #022955;
    margin: auto;
    // margin-left: 62px;
}
.search_right {
    flex: 1;
    position: relative;
}
.search_input {
    width: 100%;
    word-wrap: break-word;
    line-height: 30px;
    outline: none;
    border: none;
    padding: 0 20px;
    font-size: 14px;
}
.search_btn {
    width: 65px;
    height: 54px;
    cursor: pointer;
}
.search_btn img {
    width: 24px;
}
/* 选择下拉 */
.search_left {
    width: 170px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}
.search_left::after {
    content: '';
    position: absolute;
    right: 0;
    top: 17px;
    width: 1px;
    height: 20px;
    background-color: #e7eaed;
}
.search_left /deep/ .el-input__inner {
    border: none;
    font-size: 16px;
}
/deep/ .el-input__inner {
    padding-right: 0px !important;
}
.country_flag {
    display: flex;
    align-items: center;
}
.country_flag_img {
    width: 36px;
    height: 22px;
    margin: 15px 2px 15px 15px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.sel_value {
    width: 64px;
    font-size: 14px;
    word-break: break-all;
}
.search_left .el-select {
    position: absolute;
    top: 0;
    width: 144px;
}
.search_left /deep/ .el-select .el-input__inner {
    background-color: transparent;
    height: 52px;
    /* opacity: 0; */
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_left.on /deep/ .el-select .el-input__inner {
    padding-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.head_pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
    color: #022955;
}
.head_pro_cas /deep/ .el-icon-arrow-down:before,
.search_left /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
    font-weight: 600;
}
.sel_place {
    width: 100%;
    line-height: 52px;
    padding-left: 20px;
    display: inline-block;
    font-size: 14px;
}
.el-select-dropdown__wrap {
    max-height: 375px !important;
}
/* .el-cascader__dropdown{
z-index: 9999999 !important;
} */
.el-select-dropdown__list {
    padding: 10px 0 !important;
}
.search_sel_container {
    width: 1200px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
    width: 240px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background-color: #eee;
}

.head_swi_keyword {
    position: relative;
}
.head_swi_keyword .el-switch,
.head_login .el-switch {
    height: 34px;
    line-height: 34px;
}
.head_swi_keyword /deep/ .el-switch__core,
.head_login /deep/ .el-switch__core {
    width: 98px !important;
    height: 34px;
    background: #006a9a;
    box-shadow: inset 0px 1px 2px 0px #054562;
    border-radius: 17px;
    border: none;
}
.head_swi_keyword /deep/ .el-switch__core {
    width: 100px !important;
}
.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core {
    background: #ff7600;
    box-shadow: inset 0px 1px 2px 0px #aa4f00;
}
.head_swi_keyword /deep/ .el-switch__core:after,
.head_login /deep/ .el-switch__core:after {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    background: #ffffff;
}

.head_swi_keyword /deep/ .el-switch.is-checked .el-switch__core::after {
    left: 86px;
}
.search_sel_container /deep/ .el-tabs__active-bar {
    display: none;
}
.search_sel_container .right {
    width: 959px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 340px;
    align-content: flex-start;
    margin-right: 1px;
    padding: 0 12px;
    overflow: auto;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
    height: 42px;
    line-height: 42px;
    margin-bottom: 8px;
        padding: 0 10px !important;
}
.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
    background: rgba(18, 144, 201, 0.1);
}

.el-select-dropdown__item:hover {
    background: rgba(18, 144, 201, 0.1);
}

.search_sel_container /deep/ .el-tabs__item:hover {
    color: #1290c9;
    font-weight: normal;
}
.search_sel_container .left_txt {
    font-size: 16px;
}
.search_sel_container .right_txt {
    font-size: 12px;
    color: #8497ab;
}
.search_sel_container .right .el-select-dropdown__item {
    width: 222px;
    height: 56px;
    padding: 0 10px;
    margin: 10px 5px;
    border: 1px solid #fff;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
    background: rgba(18, 144, 201, 0.1);
    border-radius: 14px;
    border: 1px solid #1290c9;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
    color: #022955;
    font-weight: normal;
}
.right_item {
    height: 100%;
    width: 100%;
}
.search_sel_container .right .img {
    width: 54px;
    height: 100%;
    margin-right: 5px;
}
.search_sel_container .right .img img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.search_sel_container .right .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search_sel_container .right .rBox .coun_title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search_sel_container .right .rBox .coun_num {
    font-size: 12px;
    line-height: 1;
}
.table_box /deep/ .el-table__empty-block .el-table__empty-text {
    width: auto;
    /* justify-content: flex-start !important; */
}
</style>